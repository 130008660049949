import React from 'react';
import styled from 'styled-components';
import UseCaseItem from './useCaseItem';

import ResponsiveUseCases from './responsiveUseCases';
import { reAuthenticateUseCases as data } from '../../../../helper/constant';

const StyledSection = styled.section`
    max-width: 80vw;
    margin: 100px auto 0px auto;

    @media screen and (max-width: 800px) {
        margin: 20px auto 00px auto;
        max-width: 100vw;
        height: 50%;
    }
`;

const StyledTitle = styled.h1`
    font-family: Rubik;
    font-size: 42px;
    font-weight: 700;
    line-height: 49.77px;
    text-align: center;
    color: #262626;

    @media screen and (max-width: 800px) {
        font-size: 24px;
    }
`;

const StyledContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 80px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const StyledLeftContainer = styled.div``;

const StyledRightContainer = styled.div``;

const UseCaseSection = () => {
    return (
        <StyledSection>
            <StyledTitle>Use Cases</StyledTitle>
            <StyledContainer>
                <StyledLeftContainer>
                    <UseCaseItem
                        heading={data['eCommerce'].heading}
                        content={data['eCommerce'].content}
                        headingIconSrc={data['eCommerce'].headingIconSrc}
                    />
                    <UseCaseItem
                        heading={data['onlineMarketPlace'].heading}
                        content={data['onlineMarketPlace'].content}
                        headingIconSrc={data['onlineMarketPlace'].headingIconSrc}
                    />
                </StyledLeftContainer>
                <StyledRightContainer>
                    <UseCaseItem
                        heading={data['peerToPeerLending'].heading}
                        content={data['peerToPeerLending'].content}
                        headingIconSrc={data['peerToPeerLending'].headingIconSrc}
                    />
                    <UseCaseItem
                        heading={data['onlineCheckIn'].heading}
                        content={data['onlineCheckIn'].content}
                        headingIconSrc={data['onlineCheckIn'].headingIconSrc}
                    />
                    <UseCaseItem
                        heading={data['freelancePlatforms'].heading}
                        content={data['freelancePlatforms'].content}
                        headingIconSrc={data['freelancePlatforms'].headingIconSrc}
                    />
                </StyledRightContainer>
            </StyledContainer>
            <ResponsiveUseCases data={Object.values(data)} />
        </StyledSection>
    );
};

export default UseCaseSection;
