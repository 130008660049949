import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledContainer = styled.div`
    padding: 40px;

    @media screen and (max-width: 800px) {
        padding: 20px;
    }

    @media screen and (max-width: 600px) {
        padding: 0px 0px 30px 0px;
    }
`;

const StyledTitle = styled.h2`
    font-family: Rubik;
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    text-align: left;
    color: #fff;

    @media screen and (max-width: 800px) {
        font-size: 22px;
    }
`;

const StyledDescription = styled.p`
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.08px;
    text-align: left;
    color: #fff;
    margin-top: 20px;

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }

    @media screen and (max-width: 600px) {
        margin-top: 10px;
    }
`;

const BenefitItem = ({ title, description }) => {
    return (
        <StyledContainer>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
        </StyledContainer>
    );
};

BenefitItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

export default BenefitItem;
