import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledTag = styled.div`
    z-index: 1;
    border-radius: 20px;
    border: 1px solid ${({ color }) => color || '#fff'};
    background: transparent;
    padding: 8px 24px;
`;

const StyledText = styled.p`
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color || '#fff'};
`;

function SimpleTag({ text, color }) {
    return (
        <StyledTag color={color}>
            <StyledText color={color}>{text}</StyledText>
        </StyledTag>
    );
}

SimpleTag.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
};

export default SimpleTag;
