import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import UseCaseItem from './useCaseItem';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const StyledWrapper = styled.div`
    position: relative;

    @media screen and (min-width: 801px) {
        display: none;
    }
`;

const StyledScrollableContainer = styled.div`
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(5, 80vw);
    gap: 20px;
    overflow-x: auto;
    margin: 0 auto;
`;

export const StyledChevronButton = styled.button`
    position: absolute;
    top: 50%;
    left: 0;
    height: 42px;
    width: 42px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    box-shadow: 0px 4px 14px 0px #00000026;
    z-index: 1;
    margin: 0px 10px;

    ${({ right }) =>
        right &&
        css`
            right: 0;
            left: auto;
        `}
`;

export const StyledChevronLeft = styled(ChevronLeft)`
    color: ${({ isDisabled }) => (isDisabled ? '#9CA3AF' : '#000')};
`;

export const StyledChevronRight = styled(ChevronRight)`
    color: ${({ isDisabled }) => (isDisabled ? '#9CA3AF' : '#000')};
`;

const ResponsiveUseCases = ({ data }) => {
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    const containerRef = useRef();

    useEffect(() => {
        const container = containerRef?.current;

        const handleScroll = () => {
            setIsPrevDisabled(container?.scrollLeft === 0);
            setIsNextDisabled(
                container?.scrollLeft + container?.offsetWidth >= container?.scrollWidth
            );
        };

        container?.addEventListener('scroll', handleScroll);
        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onSlideLeft = () => {
        const container = containerRef?.current;
        const cardWidth = container?.firstElementChild?.offsetWidth + 20;
        container?.scrollBy({
            left: -cardWidth,
            behavior: 'smooth',
        });
    };

    const onSlideRight = () => {
        const container = containerRef?.current;
        const cardWidth = container?.firstElementChild?.offsetWidth + 20;
        container?.scrollBy({
            left: cardWidth,
            behavior: 'smooth',
        });
    };

    return (
        <StyledWrapper>
            <StyledChevronButton disabled={isPrevDisabled} onClick={onSlideLeft}>
                <StyledChevronLeft isDisabled={isPrevDisabled} />
            </StyledChevronButton>
            <StyledChevronButton right disabled={isNextDisabled} onClick={onSlideRight}>
                <StyledChevronRight isDisabled={isNextDisabled} />
            </StyledChevronButton>
            <StyledScrollableContainer ref={containerRef}>
                {data.map((item) => (
                    <UseCaseItem
                        key={item.id}
                        heading={item.heading}
                        content={item.content}
                        headingIconSrc={item.headingIconSrc}
                    />
                ))}
            </StyledScrollableContainer>
        </StyledWrapper>
    );
};

ResponsiveUseCases.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            heading: PropTypes.string,
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        title: PropTypes.string,
                        iconSrc: PropTypes.string,
                        description: PropTypes.string,
                    })
                ),
            ]),
        })
    ),
};

export default ResponsiveUseCases;
