import React from 'react';
import styled from 'styled-components';
import ImageInfoSection from '../../../components/reusable/ImageInfoSection';
import ReauthOnboard from '../../../images/product-assets/reauth_onboard.svg';
import ReauthKyc from '../../../images/product-assets/reauth_kyc.svg';
import ReauthScale from '../../../images/product-assets/reauth_scale.svg';

const StyledSection = styled.section`
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px auto 0px auto;

    @media screen and (max-width: 800px) {
        margin-top: 50px;
    }
`;

const StyledHeading = styled.h1`
    font-size: 42px;
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
    color: #262626;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: -20px;
    }
`;

const ProductInfoSection = () => {
    return (
        <StyledSection>
            <StyledHeading>What is ReAuthenticate™?</StyledHeading>
            <ImageInfoSection
                title="Onboard users in minutes, not days"
                description="ReAuthenticate™ makes onboarding quick and easy. Forget about filling out forms or scanning IDs. With just a selfie, our smart technology verifies your users instantly. Enjoy higher conversions, happier users, and significant savings in both time and money with this FREE KYC."
                imgSrc={ReauthOnboard}
                imgAlt="reauth_onboard.svg"
            />
            <ImageInfoSection
                title="Simplify Compliance, Simplify Lives: Effortless KYC with ReAuthenticate™"
                description="With KYC you must use no less than two factors of authentication to prove identity. ReAuthenticate™ makes compliance a breeze, allowing businesses and end users to enjoy a smooth, mobile-friendly onboarding experience. If we've verified this user before, then with just a selfie we can ensure our secure solution meets your regulatory requirements."
                imgSrc={ReauthKyc}
                imgAlt="reauth_kyc.svg"
                direction="row-reverse"
            />
            <ImageInfoSection
                title="Scale seamlessly to accommodate your growing user base"
                description="ReAuthenticate™ can manage surging demand without compromising speed, accuracy, and user experience. Perfect for rapidly growing businesses, ReAuthenticate™ ensures you are always KYC compliant. Any entity, large or small can enjoy this seamless solution with peace of mind and reliability."
                imgSrc={ReauthScale}
                imgAlt="reauth_scale.svg"
            />
        </StyledSection>
    );
};

export default ProductInfoSection;
