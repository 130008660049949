import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import SimpleTag from '../../../components/reusable/SimpleTag';

import BackgroundImage from '../../../images/product-assets/reauthenticate-bg.svg';
import { useModal } from '../../../context/ModalContext';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 600px) {
        min-height: auto;
    }
`;

const HeroContainer = styled.div`
    position: relative;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 70%;
        width: 100%;
        background: url(${BackgroundImage}),
            linear-gradient(90deg, #4694c4 3.57%, #6b3bfb 34.1%, #458ac2 74.3%, #130a68 105.34%);
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: saturation;

        @media screen and (max-width: 912px) {
            height: 90vh;
            background: url(${BackgroundImage});
            background-blend-mode: none;
            background-size: cover;
        }

        @media screen and (max-width: 600px) {
            height: 70vh;
        }
    }

    @media screen and (max-width: 912px) {
        height: 90vh;
    }

    @media screen and (max-width: 600px) {
        height: 100%;
    }
`;

const StyledWrapper = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 95px 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 800px) {
        height: 70vh;
        padding: 100px 16px 32px 16px;
        max-width: 90vw;
    }
`;

const Heading = styled.h1`
    color: #fff;
    font-size: 64px;
    font-weight: 500;
    line-height: 76px;
    text-align: center;
    margin-top: 28px;
    z-index: 3;

    @media screen and (max-width: 600px) {
        font-size: 24px;
        text-wrap: pretty;
        font-weight: 500;
        line-height: 28px;
        margin-top: 30px;
    }
`;

const SubHeading = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #fff;
    margin-top: 28px;
    width: 50%;
    z-index: 4;

    @media screen and (max-width: 500px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-align: center;
        margin-top: 26px;
        text-wrap: balance;
        width: 100%;
    }
`;

const BtnGroup = styled.div`
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
    z-index: 3;

    @media screen and (max-width: 500px) {
        margin-top: 26px;
        flex-direction: column;
    }
`;

const SharedCtaStyle = css`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #fff;
    background: transparent;
    padding: 12px 24px;
    border-radius: 6px;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #fff;
    &:hover {
        transform: scale(1.1);
        transition: all 0.2s;
    }

    @media screen and (max-width: 500px) {
        width: 200px;
        text-align: center;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            border: none;
            background: #fff;
            color: #0c1a68;
            @media screen and (max-width: 912px) {
                margin-bottom: -5px;
            }
        `};
`;

const CtaLink = styled.a`
    ${SharedCtaStyle}
`;

const CtaButton = styled.button`
    ${SharedCtaStyle}
    outline: none;
`;

const ProductAnimation = styled.img`
    width: 100%;
    z-index: 2;
    margin-top: 75px;

    @media screen and (max-width: 912px) {
        display: none;
    }
`;

function HeroSection({ tag, subHeading, heroImage }) {
    const { openModal } = useModal();
    return (
        <Container id="heroSection">
            <HeroContainer>
                <StyledWrapper>
                    <SimpleTag text={tag} />
                    <Heading>
                        ReAuthenticate™ & <br /> eKYC with just a Selfie
                    </Heading>
                    <SubHeading>{subHeading}</SubHeading>
                    <BtnGroup>
                        <CtaLink
                            secondary
                            href="https://portal.authenticate.com/register?utm_source=products&utm_medium=reauthenticate&utm_campaign=hero"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Sign Up
                        </CtaLink>
                        <CtaButton type="button" onClick={() => openModal('contact')}>
                            Schedule a Demo
                        </CtaButton>
                    </BtnGroup>
                    <ProductAnimation src={heroImage} alt={tag} />
                </StyledWrapper>
            </HeroContainer>
        </Container>
    );
}

HeroSection.propTypes = {
    tag: PropTypes.string,
    subHeading: PropTypes.string,
    heroImage: PropTypes.string,
};

export default HeroSection;
