import React from 'react';
import styled from 'styled-components';

import ReauthBenefit from '../../../../images/product-assets/reauth_benefit.svg';
import BenefitItem from './benefitItem';
import { reauthentcateBenefits } from '../../../../helper/constant';

const StyledSection = styled.section`
    height: auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #4d53f4;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background-color: #0c1a68;

        @media screen and (max-width: 912px) {
            display: none;
        }
    }

    @media screen and (max-width: 800px) {
        margin-top: 20px;
    }
`;

const StyledWrapper = styled.div`
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const StyledLeftContainer = styled.div`
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 30%;
    height: 100%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        padding: 50px 20px 30px 20px;
    }
`;

const StyledRightContainer = styled.div`
    padding: 50px 100px;
    height: 100%;
    width: 70%;
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: #0c1a68;
        z-index: -1;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        padding: 30px 20px 50px 20px;
    }
`;

const StyledImage = styled.img``;

const StyledSectionTitle = styled.h1`
    font-family: Rubik, sans-serif;
    color: #fff;
    font-size: 48px;
    font-weight: 800;
    line-height: 52.8px;
    text-align: left;
    margin-top: 30px;

    @media screen and (max-width: 500px) {
        font-size: 30px;
        line-height: 33px;
        margin-top: 20px;
    }
`;

const BenefitSection = () => {
    return (
        <StyledSection>
            <StyledWrapper>
                <StyledLeftContainer>
                    <StyledImage src={ReauthBenefit} alt="reauth_benefit.svg" />
                    <StyledSectionTitle>Benefits</StyledSectionTitle>
                </StyledLeftContainer>
                <StyledRightContainer>
                    {reauthentcateBenefits.map((benefit) => (
                        <BenefitItem
                            key={benefit.id}
                            title={benefit.title}
                            description={benefit.description}
                        />
                    ))}
                </StyledRightContainer>
            </StyledWrapper>
        </StyledSection>
    );
};

export default BenefitSection;
