import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledContainer = styled.div`
    margin-top: 20px;
    padding: 36px 40px;
    border-radius: 20px;
    border: 1px solid #d1d5db;
    max-width: 480px;

    @media screen and (max-width: 800px) {
        max-width: 100vw;
        padding: 36px 20px;
    }
`;

const StyledHeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledHeading = styled.h3`
    font-family: Rubik, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.55px;
    text-align: left;
    color: #0c1a68;

    @media screen and (max-width: 800px) {
        font-size: 22px;
        line-height: 26px;
    }
`;

const StyledIcon = styled.img`
    @media screen and (max-width: 800px) {
        width: 35px;
        height: 35px;
    }
`;

const StyledContentWrapper = styled.div`
    margin-top: 30px;
`;

const StyledContent = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const StyledContentItem = styled.div`
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`;

const StyledContentItemIcon = styled.img`
    @media screen and (max-width: 800px) {
        width: 35px;
        height: 35px;
    }
`;

const StyledContentItemTitle = styled.h4`
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #262626;
`;

const StyledDescription = styled.p`
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24.8px;
    text-align: left;
    color: #6b7280;
    margin-top: 10px;

    @media screen and (max-width: 800px) {
        width: 100%;
        flex: auto;
        margin-top: 20px;
        font-size: 14px;
        line-height: 21px;
    }
`;

const StyledContentMobile = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (min-width: 801px) {
        display: none;
    }
`;

const StyledContentItemMobile = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: -12px;
`;

const UseCaseItem = ({ heading, headingIconSrc, content }) => {
    return (
        <StyledContainer>
            <StyledHeadingWrapper>
                <StyledIcon src={headingIconSrc} alt="icon" />
                <StyledHeading>{heading}</StyledHeading>
            </StyledHeadingWrapper>
            <StyledContentWrapper>
                {Array.isArray(content) ? (
                    content.map((item) => (
                        <Fragment key={item.id}>
                            <StyledContent>
                                <StyledContentItemIcon src={item.iconSrc} alt="icon" />
                                <StyledContentItem>
                                    <StyledContentItemTitle>{item.title}:</StyledContentItemTitle>
                                    <StyledDescription>{item.description}</StyledDescription>
                                </StyledContentItem>
                            </StyledContent>
                            <StyledContentMobile>
                                <StyledContentItemMobile>
                                    <StyledContentItemIcon src={item.iconSrc} alt="icon" />
                                    <StyledContentItemTitle>{item.title}:</StyledContentItemTitle>
                                </StyledContentItemMobile>
                                <StyledDescription>{item.description}</StyledDescription>
                            </StyledContentMobile>
                        </Fragment>
                    ))
                ) : (
                    <StyledDescription>{content}</StyledDescription>
                )}
            </StyledContentWrapper>
        </StyledContainer>
    );
};

UseCaseItem.propTypes = {
    heading: PropTypes.string,
    headingIconSrc: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
                iconSrc: PropTypes.string,
                description: PropTypes.string,
            })
        ),
    ]),
};

export default UseCaseItem;
