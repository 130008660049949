import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    padding: 40px 20px 20px 20px;
    flex-direction: ${({ direction }) => direction};

    @media screen and (max-width: 800px) {
        padding: 30px;
        align-items: center;
        flex-direction: column-reverse;
        gap: 0px;
    }
`;

const StyledTextWrapper = styled.div`
    padding-top: 30px;
    width: 50%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

const StyledTitle = styled.h3`
    font-weight: 700;
    color: #262626;
    font-family: 'Rubik', sans-serif;
    font-size: 30px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const StyledTitleMobile = styled.h3`
    display: none;
    font-weight: 700;
    color: #262626;
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    text-align: center;

    @media screen and (max-width: 800px) {
        display: block;
        font-size: 22px;
        line-height: 26px;
        margin-top: 10px;
    }
`;

const StyledDescription = styled.p`
    color: #6b7280;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    @media screen and (max-width: 800px) {
        margin-top: 0px;
        margin-bottom: -20px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const StyledImage = styled.img`
    max-height: 400px;
    width: 50%;
    @media screen and (max-width: 600px) {
        width: 100%;
        margin: 20px auto 0px auto;
    }
`;

const ImageInfoSection = ({ title, description, imgSrc, imgAlt, direction = 'row' }) => {
    return (
        <StyledContainer direction={direction}>
            <StyledTextWrapper>
                <StyledTitle>{title}</StyledTitle>
                <StyledDescription>{description}</StyledDescription>
            </StyledTextWrapper>
            <StyledImage src={imgSrc} alt={imgAlt} />
            <StyledTitleMobile>{title}</StyledTitleMobile>
        </StyledContainer>
    );
};

ImageInfoSection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    direction: PropTypes.oneOf('row', 'row-reverse'),
};

export default ImageInfoSection;
