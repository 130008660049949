import React from 'react';
// components
import MetaTagsProvider from '../../components/MetaTagsProvider';
import HeroSection from '../../sections/Product/Reauthenticate/heroSection';
import ReAuthenticateDemo from '../../images/product-assets/reauthenticateDemo.gif';
import ProductInfoSection from '../../sections/Product/Reauthenticate/productInfoSection';
import BenefitSection from '../../sections/Product/Reauthenticate/benefits/benefitSection';
import UseCaseSection from '../../sections/Product/Reauthenticate/useCases/useCaseSection';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import { reauthenticateFaq, TCM_GET_STARTED_BANNER_CONTENTS } from '../../helper/constant';
// layout
import RootLayout from '../../layouts/RootLayout';
import Vendors from '../../components/reusable/vendors/Index';
import BottomBanner from '../../components/reusable/BottomBanner';

const ReauthenticatePage = () => {
    return (
        <>
            <MetaTagsProvider
                title="ReAuthenticate™: Effortless Identity Verification with Just a Selfie"
                description="Streamline your onboarding and Know Your Customer (KYC) compliance with ReAuthenticate™, a simple SaaS that turns electronic KYC into a fast, seamless user experience."
                path="products/reauthenticate"
                ogImage="https://cdn.authenticating.com/public/website/product_pages/reauthenticate_og_image.png"
                key="Age Check API, Age Verification API, Background Verification API, Background Verification SDK, Criminal Background Check API, Criminal Conviction Reporting, Driver license Verification API, Education Verification API, Employment Verification API, ID Verification & Background Check Cloud, Identity Authentication, Identity Verification API, KBA API, Knowledge Based Authentication API, Knowledge Based Authentication Quiz, Knowledge Based Authentication Solutions, License Verification API, Email Verification, Evictions API, Facial Recognition API, Global Watch List Report, Multi-Factor Verifications, National Criminal Check, Passport Authentication, Professional License Verification, SMS Verification, SSN Verification, US Corporation or Business Verification, Verify US Passport Data, Verify US Photo ID Data"
            />
            <RootLayout
                isReauthenticate
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=reauthenticate&utm_campaign=navbar"
            >
                <HeroSection
                    tag="Products · ReAuthenticate™"
                    subHeading="Streamline onboarding and KYC with ReAuthenticate™, a simple SaaS solution that turns an ordinarily painful process, into a seamless user experience."
                    heroImage={ReAuthenticateDemo}
                />
                <Vendors />
                <ProductInfoSection />
                <BenefitSection />
                <UseCaseSection />
                <ProductFaq
                    faqData={reauthenticateFaq}
                    subHeading="Everything you need to know about the ReAuthenticate™."
                    titleColor="#262626"
                    reauthenticate={true}
                />
                <BottomBanner
                    data={TCM_GET_STARTED_BANNER_CONTENTS}
                    getStartedCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=reauthenticate&utm_campaign=footer"
                />
            </RootLayout>
        </>
    );
};

export default ReauthenticatePage;
